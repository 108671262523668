export default [
  {
    path: "/attendance",
    name: "attendance",
    permission: "public",
    component: () => import("@/modules/attendance/views/index"),
    meta: {
      pageTitle: "Global.Attendance",
      breadcrumb: [{ text: "Global.Attendance", active: true }],
    },
  },
];
